/* CSS Critique pour la page d'accueil */
.hero {
    display: flex;
    height: 600px;
    margin: -2rem -2rem 2rem -2rem;
    background: var(--background-gradient);
    position: relative;
    overflow: hidden;
}

.hero-left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.hero-content {
    text-align: center;
    max-width: 80%;
}

.hero-content h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    color: white;
}

.hero-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column;
        height: auto;
        padding: 2rem 1rem;
        margin: 0;
    }

    .hero-left {
        width: 100%;
        padding: 2rem 0;
    }

    .hero-image {
        position: relative;
        width: 100%;
        height: 200px;
        margin-top: 1rem;
    }
}

/* Styles pour la section de présentation */
.presentation-section {
    padding-top: 2rem;
    background: var(--background-light);
    text-align: center;
}

.presentation-section .container {
    max-width: 1200px;
    margin: 0 auto;
}

.section-title {
    font-size: 2.5rem;
    color: var(--text-primary);
    text-align: center;
    margin-bottom: 3rem;
}

.content-block {
    margin-bottom: 3rem;
    opacity: 0;
    transform: translateY(20px);
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.content-block h3 {
    font-size: 1.8rem;
    color: var(--text-primary);
    margin-bottom: 1rem;
    text-align: center;
}

.content-block p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: var(--text-secondary);
    text-align: center;
}

.benefits-block {
    background: var(--background-accent);
    border-radius: 8px;
    margin: 3rem auto;
    opacity: 0;
    transform: translateY(20px);
    max-width: 800px;
    text-align: center;
}

.benefits-block h3 {
    text-align: center;
}

.benefits-block ul {
    list-style: none;
    padding: 0;
    display: inline-block;
    text-align: left;
    margin: 0 auto;
}

.benefits-block li {
    margin: 1rem 0;
    padding-left: 1.5rem;
    position: relative;
    font-size: 1.1rem;
}

.benefits-block li::before {
    content: "→";
    position: absolute;
    left: 0;
    color: var(--accent-color);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-30px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-in {
    animation: fadeIn 0.8s ease-out forwards;
}

.slide-in {
    animation: slideIn 0.8s ease-out forwards;
}

/* Délais d'animation pour les blocs */
.content-block:nth-child(2) { animation-delay: 0.2s; }
.content-block:nth-child(3) { animation-delay: 0.4s; }
.content-block:nth-child(4) { animation-delay: 0.6s; }
.benefits-block { animation-delay: 0.8s; }
.cta-block { animation-delay: 1s; }

/* Responsive */
@media (max-width: 768px) {
    .presentation-section {
        padding: 2rem 1rem;
    }

    .section-title {
        font-size: 2rem;
    }

    .content-block h3 {
        font-size: 1.5rem;
    }

    .content-block p,
    .benefits-block li,
    .cta-text {
        font-size: 1rem;
    }
}

/* Section CTA */
.cta-section {
    padding: 4rem 2rem;
    background: var(--background-gradient);
    color: white;
    text-align: center;
}

.cta-section .container {
    max-width: 1200px;
    margin: 0 auto;
}

.cta-block {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    opacity: 0;
    transform: translateY(20px);
}

.cta-text {
    font-size: 1.3rem;
    line-height: 1.6;
    margin-bottom: 2.5rem;
    color: white;
}

.cta-button {
    padding: 1rem 2.5rem;
    font-size: 1.2rem;
    background: var(--accent-color);
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

/* Responsive pour la section CTA */
@media (max-width: 768px) {
    .cta-section {
        padding: 4rem 1rem;
    }

    .cta-text {
        font-size: 1.1rem;
    }

    .cta-button {
        padding: 0.8rem 2rem;
        font-size: 1.1rem;
    }
}
