/* Hero Section Styles */
.hero {
    display: flex;
    height: 600px;
    margin: -2rem -2rem 2rem -2rem;
    background: var(--background-gradient);
    position: relative;
    overflow: hidden;
}

.hero-left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.hero-content {
    text-align: center;
    max-width: 80%;
}

.hero-content h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    color: white;
}

.hero-content p,
.hero-subtitle {
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 1rem;
    line-height: 1.6;
}

.hero-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    height: 100%;
    object-fit: cover;
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero {
        flex-direction: column;
        height: auto;
        padding: 2rem 1rem;
        margin: 0;
    }

    .hero-left {
        width: 100%;
        padding: 2rem 0;
    }

    .hero-content {
        padding: 1rem;
    }

    .hero-content h1 {
        font-size: 2.5rem;
    }

    .hero-content p,
    .hero-subtitle {
        font-size: 1.1rem;
    }

    .hero-image {
        position: relative;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 200px;
        margin-top: 1rem;
    }
}
