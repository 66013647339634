@import '../styles/components/Hero.css';

/* Hero Section */
.tech-gadgets-page {
    min-height: 100vh;
    padding-bottom: 2rem;
}

/* Override hero background for this page */
.hero {
    background: linear-gradient(to right, var(--background-color) 60%, transparent);
}

@media (max-width: 768px) {
    .hero {
        background: linear-gradient(to bottom, var(--background-color) 60%, transparent);
    }
}

/* Category Section Styles */
.category-section {
    padding: 2rem 4rem;
    margin: 2rem 0;
}

.section-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.section-icon {
    font-size: 2rem;
    color: var(--primary-color);
}

.section-text h2 {
    margin: 0;
    color: var(--text-color);
}

.section-text p {
    margin: 0.5rem 0 0;
    color: var(--text-secondary);
}

.content-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    max-width: 1200px;
    margin: 0 auto;
}

.tech-card {
    background: var(--background-primary);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.tech-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.tech-card a {
    text-decoration: none;
    color: inherit;
}

.card-content {
    padding: 1.5rem;
}

.card-content h3 {
    margin: 0 0 0.5rem;
    color: var(--text-color);
}

.card-content p {
    margin: 0;
    color: var(--text-secondary);
    font-size: 0.9rem;
    line-height: 1.5;
}

/* Forum Section Styles */
.discussions-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.discussions-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.loading {
    text-align: center;
    padding: 2rem;
    color: var(--text-secondary);
}

.round-button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--bg-primary);
    color: var(--text-primary);
    border: 2px solid var(--text-primary);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: normal;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.round-button:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .category-section {
        padding: 1rem;
    }

    .content-grid {
        grid-template-columns: 1fr;
    }

    .section-header {
        flex-direction: column;
        text-align: center;
        gap: 0.5rem;
    }
}
