.comments-section {
    margin-top: 20px;
    padding-top: 1rem;
    border-top: 1px solid var(--border-color);
}

.comment-form {
    margin-bottom: 30px;
    background: var(--background-secondary);
    padding: 20px;
    border-radius: 8px;
}

.comment-input-container {
    margin-bottom: 15px;
}

.comment-input-container .rich-text-editor {
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

.comment-input-container .rich-text-editor .ql-toolbar {
    border-bottom: 1px solid var(--border-color);
    background: var(--background-secondary);
}

.comment-input-container .rich-text-editor .ql-container {
    min-height: 120px;
}

.submit-comment-button {
    background-color: var(--primary-color);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
}

.submit-comment-button:hover {
    background-color: var(--primary-color-dark);
}

.submit-comment-button:disabled {
    background-color: var(--disabled-color);
    cursor: not-allowed;
}

.comments-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.comment {
    background-color: var(--card-background);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid var(--border-color);
}

.comment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
}

.comment-author {
    display: flex;
    align-items: center;
}

.author-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 1;
}

.author-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.author-details {
    display: flex;
    flex-direction: column;
}

.author-name {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
    color: var(--text-primary);
}

.comment-date {
    text-align: right;
    color: var(--text-secondary);
    font-size: 0.9rem;
}

.comment-content {
    color: var(--text-primary);
    margin-bottom: 1rem;
    line-height: 1.5;
}

.comment-actions {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.like-button,
.edit-button,
.delete-button {
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-secondary);
    transition: all 0.2s ease;
}

.like-button:hover,
.edit-button:hover,
.delete-button:hover {
    color: var(--primary-color);
}

.like-button.liked {
    color: #e74c3c;
}

.delete-button:hover {
    color: #e74c3c;
}

.edit-form {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.edit-form .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
}

.edit-form button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: all 0.2s;
}

.edit-form button:first-child {
    background: var(--background-color);
    border: 1px solid var(--border-color);
    color: var(--text-primary);
}

.edit-form button:last-child {
    background: var(--accent-color);
    color: white;
}

.edit-form button:hover {
    opacity: 0.8;
}

.no-comments {
    text-align: center;
    color: var(--text-secondary);
    padding: 1rem;
    font-style: italic;
}

.spoiler-container {
    margin: 8px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
}

.spoiler-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    width: 100%;
    background-color: #f5f5f5;
    border: none;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    font-size: 0.9em;
    color: #666;
    transition: background-color 0.2s;
}

.spoiler-button:hover {
    background-color: #eee;
}

.spoiler-button svg {
    font-size: 1em;
}

.spoiler-content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    background-color: white;
    padding: 0 12px;
}

.spoiler-content.revealed {
    max-height: 1000px;
    opacity: 1;
    padding: 12px;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
    .spoiler-container {
        border-color: #444;
    }

    .spoiler-button {
        background-color: #2a2a2a;
        border-bottom-color: #444;
        color: #ccc;
    }

    .spoiler-button:hover {
        background-color: #333;
    }

    .spoiler-content {
        background-color: #1a1a1a;
    }

    .comment-input-container .rich-text-editor {
        background: var(--background-secondary);
    }

    .comment-input-container .rich-text-editor .ql-toolbar {
        background: var(--background-color);
    }

    .comment-input-container .rich-text-editor .ql-editor {
        color: var(--text-primary);
    }
}
