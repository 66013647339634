.legal-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 90px 20px 40px; /* Ajusté pour la navbar */
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.legal-header {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 40px;
    text-align: center;
    width: 100%;
    position: relative; /* Pour s'assurer qu'il reste au-dessus du contenu mais sous la navbar */
    z-index: 1;
}

.legal-header h1 {
    font-size: 2.5rem;
    color: #1a1a1b;
    margin-bottom: 15px;
    font-weight: bold;
}

.legal-header p {
    color: #7c7c7c;
    font-size: 1.1rem;
    margin: 0;
}

.legal-section {
    margin-bottom: 40px;
    background: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    width: 100%;
}

.legal-section:first-of-type {
    padding-top: 40px;
    margin-top: 20px;
}

.legal-section h2 {
    color: #1a1a1b;
    font-size: 1.8rem;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #edeff1;
}

.legal-section h3 {
    color: #1a1a1b;
    font-size: 1.4rem;
    margin: 25px 0 15px;
}

.legal-section p {
    color: #1c1c1c;
    line-height: 1.6;
    margin-bottom: 15px;
}

.legal-section ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
}

.legal-section li {
    color: #1c1c1c;
    line-height: 1.6;
    margin-bottom: 10px;
}

.legal-section a {
    color: #46d160;
    text-decoration: none;
}

.legal-section a:hover {
    text-decoration: underline;
}

.date-update {
    text-align: right;
    color: #7c7c7c;
    font-size: 0.9rem;
    margin-top: 30px;
    font-style: italic;
}
