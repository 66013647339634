.rich-text-editor {
  border-radius: 4px;
  transition: all 0.3s ease;
}

.rich-text-editor.focused {
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.rich-text-editor .ql-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #fff;
}

.rich-text-editor .ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #f8f9fa;
  border-color: #dee2e6;
}

.rich-text-editor .ql-editor {
  min-height: 200px;
  font-size: 16px;
  line-height: 1.5;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .rich-text-editor .ql-container {
    background: #2d3436;
    border-color: #4a5568;
  }

  .rich-text-editor .ql-toolbar {
    background: #2d3436;
    border-color: #4a5568;
  }

  .rich-text-editor .ql-editor {
    color: #e2e8f0;
  }

  .rich-text-editor .ql-stroke {
    stroke: #e2e8f0;
  }

  .rich-text-editor .ql-fill {
    fill: #e2e8f0;
  }

  .rich-text-editor .ql-picker {
    color: #e2e8f0;
  }
}

/* Custom styles for toolbar buttons */
.ql-toolbar button {
  padding: 6px !important;
  margin: 2px !important;
}

.ql-toolbar button:hover {
  background-color: rgba(0, 123, 255, 0.1);
  border-radius: 4px;
}

/* Style for active toolbar items */
.ql-toolbar button.ql-active,
.ql-toolbar .ql-picker-label.ql-active {
  background-color: rgba(0, 123, 255, 0.2);
  border-radius: 4px;
}

/* Tooltip styles */
.ql-tooltip {
  background-color: #fff;
  border: 1px solid #dee2e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 12px;
}

@media (prefers-color-scheme: dark) {
  .ql-tooltip {
    background-color: #2d3436;
    border-color: #4a5568;
    color: #e2e8f0;
  }
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.form-actions button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.form-actions button:first-child {
  background: var(--background-color);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
}

.form-actions button:last-child {
  background: var(--accent-color);
  color: white;
}

.form-actions button:hover {
  opacity: 0.8;
}
