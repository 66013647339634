.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  text-align: center;
  padding: 20px;
}

.not-found h1 {
  font-size: 120px;
  margin: 0;
  color: #4e6f8f;
}

.not-found h2 {
  font-size: 32px;
  margin: 10px 0;
  color: #4e6f8f;
}

.not-found p {
  font-size: 18px;
  color: #7f8c8d;
  margin: 20px 0;
}

.home-link {
  display: inline-block;
  padding: 12px 24px;
  background-color: #46d160;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.home-link:hover {
  background-color: #348142;
}
