@import '../styles/components/Hero.css';

/* Hero Section */
.altcoins-page {
    min-height: 100vh;
    padding-bottom: 2rem;
}

/* Override hero background for this page */
.hero {
    background: linear-gradient(to right, var(--background-color) 60%, transparent);
}

@media (max-width: 768px) {
    .hero {
        background: linear-gradient(to bottom, var(--background-color) 60%, transparent);
    }
}

/* Forum Section Styles */
.discussions-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.discussions-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.loading {
    text-align: center;
    padding: 2rem;
    color: var(--text-secondary);
}

.new-discussion-button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s, box-shadow 0.2s;
}

.new-discussion-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}
