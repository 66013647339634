.discussion-card {
    position: relative;
    background: var(--card-bg);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease;
}

.discussion-card.admin-post {
    border-left: 4px solid #2ecc71;
    background: linear-gradient(to right, rgba(46, 204, 113, 0.05), var(--card-bg) 50px);
}

.pinned-post {
    border: 2px solid var(--primary-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.discussion-card:hover {
    transform: translateY(-2px);
}

.discussion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--border-color);
    position: relative;
    z-index: 2;
}

.discussion-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-color);
    margin: 0.5rem 0 1rem;
    line-height: 1.4;
}

.author-info {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.author-info-text {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.author-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.discussion-date {
    color: var(--text-secondary);
    font-size: 0.9rem;
}

.pinned-indicator {
    color: var(--primary-color);
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
}

.discussion-content {
    color: var(--text-color);
    line-height: 1.6;
    margin-bottom: 1rem;
    position: relative;
    z-index: 1;
}

.discussion-text {
    white-space: pre-wrap;
    word-break: break-word;
}

.discussion-footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: var(--text-secondary);
    font-size: 0.9rem;
    position: relative;
    z-index: 2;
}

.discussion-actions {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 1rem 0;
    position: relative;
    z-index: 3;
}

.action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid var(--border-color);
    border-radius: 20px;
    background: var(--card-bg);
    color: var(--text-color);
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    z-index: 3;
}

.action-button:hover:not(:disabled) {
    background: var(--hover-bg);
    border-color: var(--primary-color);
}

.action-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.action-button.liked {
    color: #e74c3c;
    cursor: default;
}

.action-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pin-button {
    background: none;
    border: none;
    color: var(--text-secondary);
    padding: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-left: 0.5rem;
    border-radius: 4px;
}

.pin-button:hover {
    color: #2ecc71;
    background-color: var(--hover-color);
}

.pin-button.pinned {
    color: var(--primary-color);
}

.reply-form {
    margin-top: 1rem;
    position: relative;
    z-index: 2;
}

.reply-form .rich-text-editor {
    margin-bottom: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background: var(--background-color);
}

.reply-form .rich-text-editor .ql-toolbar {
    border-bottom: 1px solid var(--border-color);
    background: var(--background-secondary);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.reply-form .rich-text-editor .ql-container {
    min-height: 120px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.forum-form-actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 16px;
}

.forum-form-actions button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 14px;
}

.forum-form-actions button:first-child {
    background-color: #444;
    color: #ff0a0a;
    border: 1px solid #ff0a0a;
}

.forum-form-actions button:first-child:hover {
    background: transparent;
    color: #ff0a0a;
}

.forum-form-actions button:last-child {
    background-color: #444;
    color: #5ff89f;
    border: 1px solid #5ff89f;
}

.forum-form-actions button:last-child:hover {
    background: transparent;
    color: #2db164;
}

.form-actions {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    justify-content: flex-end;
}

.form-actions button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
}

.spoiler-container {
    margin: 8px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
}

.spoiler-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    width: 100%;
    background-color: #f5f5f5;
    border: none;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    font-size: 0.9em;
    color: #666;
    transition: background-color 0.2s;
}

.spoiler-button:hover:not(:disabled) {
    background-color: #eee;
}

.spoiler-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.spoiler-button svg {
    font-size: 1em;
}

.spoiler-content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    background-color: white;
    padding: 0 12px;
}

.spoiler-content.revealed {
    max-height: 1000px;
    opacity: 1;
    padding: 12px;
}

.author-actions {
    display: flex;
    gap: 8px;
    margin-left: auto;
}

.edit-button,
.delete-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border: none;
    border-radius: 4px;
    background: none;
    cursor: pointer;
    color: #666;
    transition: all 0.2s ease;
}

.edit-button:hover {
    color: #2196f3;
    background-color: rgba(33, 150, 243, 0.1);
}

.delete-button:hover {
    color: #f44336;
    background-color: rgba(244, 67, 54, 0.1);
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
    .spoiler-container {
        border-color: #444;
    }

    .spoiler-button {
        background-color: #2a2a2a;
        border-bottom-color: #444;
        color: #ccc;
    }

    .spoiler-button:hover:not(:disabled) {
        background-color: #333;
    }

    .spoiler-button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .spoiler-content {
        background-color: #1a1a1a;
    }

    .edit-button,
    .delete-button {
        color: #aaa;
    }

    .edit-button:hover {
        background-color: rgba(33, 150, 243, 0.2);
    }

    .delete-button:hover {
        background-color: rgba(244, 67, 54, 0.2);
    }

}

@media (max-width: 768px) {
    .discussion-header {
        flex-direction: inherit;
        align-items: inherit;
        text-align: center;
        gap: 0.5rem;
    }

    .discussion-date {
        align-self: flex-end;
    }

    .discussion-actions {
        width: 100%;
        justify-content: space-between;
    }
}
