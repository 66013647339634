.auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.auth-modal-content {
  background-color: #1a1a1a;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  position: relative;
  color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.auth-modal-close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
  background: none;
  border: none;
  padding: 0;
  line-height: 1;
}

.auth-modal-close-button:hover {
  opacity: 0.8;
}

.auth-modal-title {
  margin-bottom: 1.5rem;
  color: #fff;
  text-align: center;
}

.auth-modal-form-group {
  margin-bottom: 1rem;
}

.auth-modal-form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #ccc;
}

.auth-modal-form-input {
  width: 100%;
  padding: 0.75rem;
  background-color: #2a2a2a;
  border: 1px solid #444;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
}

.auth-modal-form-input:focus {
  outline: none;
  border-color: #00ff00;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.auth-modal-error-message {
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.3);
  color: #ff6b6b;
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.auth-modal-success-message {
  background-color: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.3);
  color: #00ff00;
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.auth-modal-footer {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.auth-modal-link-button {
  background: none;
  border: none;
  color: #ffffff;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
  text-decoration: underline;
}

.auth-modal-link-button:hover {
  color: var(--accent-color-hover);
}

.auth-modal-link-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.auth-modal-submit-btn {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 1rem;
}

.auth-modal-submit-btn:hover:not(:disabled) {
  color: black;
  background-color: var(--accent-color-hover);
}

.auth-modal-submit-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.auth-modal-toggle-forms {
  margin-top: 1.5rem;
  text-align: center;
  background-color: #333;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.auth-modal-toggle-forms:hover {
  background-color: #444;
}

.auth-modal-captcha-container {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.auth-modal-forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
}

.auth-modal-forgot-password {
  background-color: #333;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.auth-modal-form-actions {
  margin-top: 1.5rem;
}

.auth-modal-btn {
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-bottom: 0.5rem;
}

.auth-modal-btn.primary {
  background-color: var(--accent-color);
  color: white;
}

.auth-modal-btn.primary:hover:not(:disabled) {
  background-color: var(--accent-color-hover);
}

.auth-modal-btn.secondary {
  background-color: #f5f5f5;
  color: #333;
}

.auth-modal-btn.secondary:hover:not(:disabled) {
  background-color: #e8e8e8;
}

.auth-modal-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
