.user-badge {
  display: inline-flex !important;
  align-items: center !important;
  gap: 4px !important;
  padding: 2px 8px !important;
  border-radius: 12px !important;
  font-size: 0.8rem !important;
  margin-left: 8px !important;
  font-weight: bold !important;
}

.user-badge.admin {
  background-color: #FF4B4B !important;
  color: #FFFFFF !important;
}

.user-badge.admin svg {
  color: #FFFFFF !important;
}
