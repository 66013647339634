@import '../styles/components/Hero.css';

/* Page Styles */
.contact-page {
    min-height: 100vh;
    padding-bottom: 2rem;
}

/* Override hero background for this page */
.hero {
    background: linear-gradient(to right, var(--background-color) 60%, transparent);
}

@media (max-width: 768px) {
    .hero {
        background: linear-gradient(to bottom, var(--background-color) 60%, transparent);
    }
}

/* Hero Section */
.hero-left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.hero-content {
    text-align: center;
    max-width: 80%;
}

.hero-content h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    color: white;
}

.hero-subtitle {
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 1rem;
}

.hero-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    height: 100%;
    object-fit: cover;
}

/* Contact Form Styles */
.contact-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 8px;
}

/* Formulaire */
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-color);
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background: var(--input-background);
    color: var(--text-color);
}

.form-group textarea {
    min-height: 150px;
    resize: vertical;
}

/* Bouton d'envoi */
.submit-button-contact {
    background: transparent;
    color: #46d160;
    padding: 0.8rem 1.5rem;
    border: 2px solid #46d160;
    border-radius: 4px;
    size: fit-content;
    cursor: pointer;
    font-size: 1.2rem;
    transition: font-size 0.3s;
    margin: auto;
}

.submit-button-contact:hover {
    font-size: 1.35rem;
    color: #46d160;
}

.submit-button-contact:disabled {
    background: var(--disabled-color);
    cursor: not-allowed;
}

.alert {
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    margin-top: 1rem;
    text-align: center;
}

.alert.success {
    background: var(--success-color);
    color: white;
}

.alert.error {
    background: var(--error-color);
    color: white;
}

/* Carte d'informations de contact */
.contact-info {
    background: var(--background-secondary);
    align-self: center;
    max-width: 600px;
    margin: 0 auto;
}

.contact-info .tech-card {
    background: var(--background-secondary);
    padding: 2rem;
    border-radius: 8px;
    height: fit-content;
    max-width: 600px;
    margin: 0 auto;
}

.contact-info h3 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: var(--text-color);
    font-size: 1.5rem;
}

.contact-info p {
    margin: 0.5rem 0;
    color: var(--text-secondary);
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero {
        flex-direction: column;
        height: auto;
        margin: 0;
    }

    .hero-left {
        width: 100%;
        padding: 2rem;
    }

    .hero-content h1 {
        font-size: 2.5rem;
    }

    .hero-subtitle {
        font-size: 1.1rem;
    }

    .hero-image {
        position: relative;
        width: 100%;
        height: 200px;
    }

    .contact-form {
        padding: 1rem;
    }
}
