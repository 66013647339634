.search-container {
    position: relative;
    width: 300px;
}

.search-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--background-secondary);
    color: var(--text-color);
    font-size: 14px;
}

.search-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.2);
}

.search-loading {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 8px;
    background-color: var(--background-primary);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    margin-top: 4px;
    text-align: center;
    color: var(--text-secondary);
    font-size: 14px;
}

.search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--background-primary);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    margin-top: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-height: 300px;
    overflow-y: auto;
}

.suggestion-item {
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
}

.suggestion-item:last-child {
    border-bottom: none;
}

.suggestion-item:hover {
    background-color: var(--background-secondary);
}

.suggestion-keyword {
    font-weight: 500;
    color: var(--text-color);
}

.suggestion-section {
    font-size: 12px;
    color: var(--text-secondary);
    margin-left: 8px;
}

@media (max-width: 768px) {
    .search-container {
        width: 100%;
        position: static;
    }

    .search-dropdown {
        position: fixed;
        top: auto;
        left: 0;
        right: 0;
        width: 100%;
        max-width: 100%;
        margin-top: 0;
        border-radius: 0;
        border-left: none;
        border-right: none;
    }
}
