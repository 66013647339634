footer {
    background-color: #1a1a1b;
    color: #ffffff;
    padding: 30px 0;
    margin-top: auto;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-logo a {
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
}

.footer-logo .tech {
    color: #ff4500;
}

.footer-logo .passion {
    color: #46d160;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.footer-copyright {
    color: #7c7c7c;
    font-size: 0.9rem;
    margin: 0;
}

.footer-links {
    display: flex;
    gap: 20px;
}

.footer-links a {
    color: #7c7c7c;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.2s ease;
}

.footer-links a:hover {
    color: #46d160;
}
