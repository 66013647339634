@import '../styles/components/Hero.css';

/* Page Styles */
.connected-security-page {
    min-height: 100vh;
}

/* Override hero background for this page */
.hero {
    background: linear-gradient(to right, var(--background-color) 60%, transparent);
}

@media (max-width: 768px) {
    .hero {
        background: linear-gradient(to bottom, var(--background-color) 60%, transparent);
    }
}

.discussions-section {
    padding: 2rem 4rem;
}

.discussions-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.loading {
    text-align: center;
    color: var(--text-secondary);
    font-size: 1.2rem;
    padding: 2rem;
}

.new-discussion-button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--primary-color);
    color: var(--text-color);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    cursor: pointer;
    transition: transform 0.2s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.new-discussion-button:hover {
    transform: scale(1.1);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: var(--background-color);
    border-radius: 10px;
    padding: 2rem;
    width: 50%;
    max-width: 1000px;
    position: relative;
}

.close-modal {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: var(--text-secondary);
    cursor: pointer;
    font-size: 1.2rem;
}

.modal h2 {
    color: var(--text-color);
    margin-bottom: 2rem;
    font-size: 1.8rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-color);
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.8rem;
    border: 2px solid var(--border-color);
    border-radius: 8px;
    background: var(--background-secondary);
    color: var(--text-color);
    font-size: 1rem;
}

.form-group textarea {
    min-height: 200px;
    resize: vertical;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
}

.form-actions button {
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.cancel-button {
    background: var(--background-secondary);
    color: var(--text-secondary);
}

.submit-button {
    background: var(--primary-color);
    color: var(--text-color);
}

.form-actions button:hover {
    opacity: 0.9;
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column;
        padding: 2rem;
        text-align: center;
    }

    .hero-content h1 {
        font-size: 2.5rem;
    }

    .hero-image {
        width: 100%;
        max-width: 100%;
    }

    .discussions-section {
        padding: 2rem;
    }

    .modal {
        width: 90%;
        margin: 1rem;
    }

    .form-actions {
        flex-direction: column;
    }

    .form-actions button {
        width: 100%;
    }
}
