@import '../styles/components/Hero.css';

.page-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.hero {
    background: linear-gradient(to right, var(--background-color) 60%, transparent);
    display: flex;
    height: 600px;
    margin: -2rem -2rem 2rem -2rem;
    position: relative;
}

.hero-left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.hero-content {
    text-align: center;
    max-width: 80%;
    flex: 1;
    padding-right: 4rem;
}

.hero-content h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    color: white;
}

.hero-subtitle {
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 1rem;
}

.hero-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    height: 100%;
    object-fit: cover;
}

.hero-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.category-section {
    background: var(--background-secondary);
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 12px;
}

.section-header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.section-icon {
    width: 48px;
    height: 48px;
    margin-right: 1rem;
    border-radius: 50%;
}

.section-text h2 {
    margin: 0;
    color: var(--text-color);
    font-size: 1.75rem;
}

.section-text p {
    margin: 0.5rem 0 0;
    color: var(--text-secondary);
}

.content-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
}

.tech-card {
    background: var(--background-primary);
    padding: 1.5rem;
    border-radius: 8px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.tech-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.tech-card h3 {
    margin: 0 0 0.5rem;
    color: var(--text-color);
}

.tech-card p {
    margin: 0;
    color: var(--text-secondary);
    font-size: 0.9rem;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .page-content {
        padding: 1rem;
    }

    .hero {
        flex-direction: column;
        padding: 2rem;
        text-align: center;
        height: auto;
        margin: 0;
        background: linear-gradient(to bottom, var(--background-color) 60%, transparent);
    }

    .hero-content {
        padding-right: 0;
        margin-bottom: 2rem;
    }

    .hero-content h1 {
        font-size: 2.5rem;
    }

    .hero-subtitle {
        font-size: 1.1rem;
    }

    .hero-image {
        position: relative;
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .category-section {
        padding: 1.5rem;
    }

    .content-grid {
        grid-template-columns: 1fr;
    }
}
