/* Variables pour les thèmes */
:root[data-theme="light"] {
    --bg-primary: #ffffff;
    --bg-secondary: #f0f0f0;
    --text-primary: #000000;
    --text-secondary: #666666;
    --accent-color: #1DB954;
    --nav-bg: #ffffff;
    --card-bg: #ffffff;
    --modal-bg: #ffffff;
    --border-color: #ddd;
    --text-color: #000000;
    --background-gradient: linear-gradient(to right, #4CAF50, #4CAF50);
    --background-color: #4CAF50;
    --accent-color-hover: #34C759;
    --card-background: #f7f7f7;
    --card-hover-background: #e6e6e6;
    --primary-color: #000000;
}

:root[data-theme="dark"] {
    --bg-primary: #121212;
    --bg-secondary: #181818;
    --text-primary: #ffffff;
    --text-secondary: #b3b3b3;
    --accent-color: #1DB954;
    --nav-bg: #000000;
    --card-bg: #282828;
    --modal-bg: #282828;
    --border-color: #333;
    --text-color: #ffffff;
    --background-gradient: linear-gradient(to right, #4CAF50, #4CAF50);
    --background-color: #4CAF50;
    --accent-color-hover: #34C759;
    --card-background: #333;
    --card-hover-background: #222;
}

/* Reset et styles de base */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: background-color 0.3s, color 0.3s;
}

body {
    font-family: 'Circular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: var(--bg-primary);
    color: var(--text-primary);
    line-height: 1.6;
    min-height: 100vh;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;
    padding-top: 80px;
}

/* Navigation */
header {
    background-color: var(--nav-bg);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--border-color);
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto;
}

.nav-left {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.mobile-menu-button {
    display: none;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--accent-color);
}

.logo a {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
    font-size: 1.5rem;
}

.logo .tech {
    color: #e63946;
}

.logo .passion {
    color: #2ecc71;
}

nav ul {
    display: flex;
    gap: 1.5rem;
    list-style: none;
}

nav a {
    color: var(--text-secondary);
    text-decoration: none;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    transition: all 0.3s;
}

nav a:hover, nav a.active {
    color: var(--text-primary);
    background-color: var(--bg-secondary);
}

.nav-right {
    display: flex;
    align-items: center;
    gap: 1rem;
}

/* Barre de recherche */
.search-bar {
    display: flex;
    align-items: center;
    background-color: var(--bg-secondary);
    border-radius: 20px;
    padding: 0.5rem 1rem;
    position: relative;
    margin-right: 1rem;
}

.search-bar input {
    background: none;
    border: none;
    color: var(--text-primary);
    padding: 0.25rem;
    width: 200px;
}

.search-bar input:focus {
    outline: none;
}

.search-bar button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-secondary);
}

/* Dropdown de recherche */
.search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: brightness(15%);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    max-height: 400px;
    overflow-y: auto;
}

/* Message aucun résultat */
.search-no-results {
    padding: 1rem;
    text-align: center;
    color: var(--text-secondary);
    font-style: italic;
}

.search-no-results span {
    display: block;
    font-size: 0.9rem;
}

/* Boutons */
.theme-toggle, .auth-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-weight: 500;
}

.auth-btn {
    background-color: var(--accent-color);
    color: white;
}

.auth-btn:hover {
    transform: scale(1.05);
}

/* Boutons de soumission */
.submit-btn {
    width: 100%;
    max-width: 200px;
    padding: 0.75rem;
    background-color: var(--accent-color);
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin: 1rem auto;
    transition: all 0.3s ease;
}

.submit-btn:hover {
    background-color: var(--accent-color-hover);
    transform: scale(1.05);
}

.modal .submit-btn {
    max-width: none;
    margin: 1rem 0;
}

/* Hero Section */
.hero {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin: -2rem -2rem 2rem -2rem;
    background: var(--background-gradient);
    max-height: 600px;
    height: 600px;
}

.hero-content {
    flex: 1;
    text-align: center;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero-content h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    color: white;
}

.hero-content p {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 2rem;
    line-height: 1.6;
}

.hero-image {
    flex: 1.2;
    height: 100%;
}

.hero-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px 0 0 10px;
}

.cta-button {
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    background: white;
    color: var(--accent-color);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s;
    text-decoration: none;
    display: inline-block;
    margin: 0 auto;
}

.cta-button:hover {
    background: black;
    color: white;
    transform: translateY(-2px);
}

@media (max-width: 992px) {
    .hero {
        flex-direction: column;
        height: auto;
        margin: -1rem -1rem 1rem -1rem;
    }

    .hero-content {
        padding: 2rem 1rem;
    }

    .hero-content h1 {
        font-size: 2.5rem;
    }

    .hero-image {
        flex: 1;
        height: 400px;
    }

    .hero-image img {
        border-radius: 0;
    }
}

@media (max-width: 576px) {
    .hero-content h1 {
        font-size: 2rem;
    }

    .hero-content p {
        font-size: 1rem;
    }

    .hero-image {
        height: 300px;
    }
}

/* Section Containers */
.section-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 2rem;
}

/* Category Sections */
.category-section {
    margin: 4rem auto;
    width: 100%;
    max-width: 800px;
    padding: 0;
}

/* Section Headers with Icons */
.section-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: var(--card-background);
    border-radius: 10px;
    width: 100%;
}

.section-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 1rem;
    object-fit: cover;
}

.section-text {
    text-align: center;
}

.section-text h2 {
    margin: 0;
    color: var(--text-primary);
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
}

.section-text p {
    margin: 0;
    color: var(--text-secondary);
}

/* Content Grid */
.content-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    width: 100%;
}

.tech-card {
    position: relative;
    background: var(--card-background);
    border-radius: 10px;
    height: 180px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    overflow: hidden;
    cursor: pointer;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.tech-card a {
    text-decoration: none;
    color: var(--text-primary);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tech-card h3 {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
    color: var(--text-primary);
    transition: color 0.3s ease;
    width: 100%;
}

.tech-card p {
    color: var(--text-secondary);
    font-size: 0.9rem;
    transition: color 0.3s ease;
    width: 100%;
}

.tech-card:hover {
    background: var(--card-hover-background);
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.tech-card:hover h3,
.tech-card:hover p {
    color: #2ecc71;
}

@media (max-width: 768px) {
    .tech-card {
        height: 150px;
        padding: 1.25rem;
    }

    .tech-card h3 {
        margin-top: 0.75rem;
        font-size: 1.1rem;
    }
}

@media (max-width: 992px) {
    .category-section {
        max-width: 700px;
    }
}

@media (max-width: 768px) {
    .category-section {
        max-width: 500px;
        margin: 3rem auto;
        padding: 0 1rem;
    }

    .content-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .tech-card {
        height: 150px;
    }
}

@media (max-width: 576px) {
    .category-section {
        margin: 2rem auto;
    }

    .tech-card {
        height: 140px;
    }
}

/* Contact Form */
.contact-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background: var(--card-bg);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-primary);
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background-color: var(--bg-primary);
    color: var(--text-primary);
}

.form-group textarea {
    resize: vertical;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: var(--accent-color);
}

.edit-title-input {
    composes: form-group input;
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background-color: var(--bg-primary);
    color: var(--text-primary);
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.edit-title-input:focus {
    outline: none;
    border-color: var(--accent-color);
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--modal-bg);
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: var(--bg-secondary);
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: var(--accent-color);
  border-radius: 4px;
}

.close-modal {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.5rem;
  z-index: 1;
  transition: color 0.2s;
}

.close-modal:hover {
  color: var(--text-primary);
}

.modal h2 {
  margin-bottom: 1.5rem;
  color: var(--text-primary);
  text-align: center;
  padding-right: 2rem;
}

/* Style spécifique pour le modal de nouvelle discussion en thème clair */
:root[data-theme="light"] .nouv-disc-modal {
  color: white;
}

:root[data-theme="light"] .nouv-disc-modal h2 {
  color: white;
}

:root[data-theme="light"] .nouv-disc-modal .form-group label {
  color: white;
}

:root[data-theme="light"] .nouv-disc-modal input,
:root[data-theme="light"] .nouv-disc-modal textarea {
  color: white;
  background-color: rgba(0, 0, 0, 0.253);
}

/* Styles pour les spoilers */
.spoiler-content {
    overflow-y: auto;
}

.spoiler-content.shown {
    min-height: fit-content;
}

/* Style spécifique pour les spoilers en thème light */
:root[data-theme="light"] .spoiler-content {
    background-color: var(--bg-primary);
    color: var(--text-primary);
}

/* Style spécifique pour les spoilers en thème dark */
:root[data-theme="dark"] .spoiler-content {
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
}

/* Avatar Selector Styles */
.avatar-selector {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.style-selector {
  margin-bottom: 1.5rem;
}

.style-selector select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  cursor: pointer;
}

.avatars-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.avatars-grid > div {
  aspect-ratio: 1;
  padding: 0.5rem;
  border: 2px solid var(--border-color);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.avatars-grid > div:hover {
  transform: translateY(-2px);
  border-color: var(--accent-color);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.avatars-grid > div.selected {
  border-color: var(--accent-color);
  background-color: var(--bg-secondary);
}

.avatars-grid img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Alert Messages */
.alert {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  text-align: center;
}

.alert.error {
  background-color: #fee2e2;
  border: 1px solid #fecaca;
  color: #dc2626;
}

.alert.success {
  background-color: #dcfce7;
  border: 1px solid #bbf7d0;
  color: #16a34a;
}

/* Submit Button */
.submit-btn {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 1rem;
}

.submit-btn:hover:not(:disabled) {
  background-color: var(--accent-color-hover);
  transform: translateY(-1px);
}

.submit-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .modal-content {
    padding: 1.5rem;
    width: 95%;
  }

  .avatars-grid {
    gap: 0.75rem;
  }

  .avatars-grid > div {
    padding: 0.25rem;
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
    .content-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.25rem;
    }
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column;
        height: auto;
        max-height: none;
        margin: 0;
    }

    .hero-content {
        padding: 4rem 1rem 2rem;
    }

    .hero-image {
        width: 100%;
        height: 400px;
    }

    .hero-image img {
        border-radius: 0;
    }

    nav {
        flex-direction: column;
        gap: 1rem;
    }

    .nav-left {
        flex-direction: column;
        gap: 0.25rem;
    }

    nav ul {
        flex-wrap: wrap;
        justify-content: center;
    }

    .nav-right {
        width: 100%;
        justify-content: center;
    }

    .search-bar {
        width: 100%;
        margin-right: 0;
    }

    .content-grid {
        grid-template-columns: 1fr;
        padding: 0 1rem;
    }

    .section-header {
        padding: 1rem;
        margin-bottom: 2rem;
    }

    .section-icon {
        margin-bottom: 0.75rem;
    }

    .tech-card {
        margin-bottom: 1rem;
    }

    .category-section {
        padding: 1rem;
        margin-bottom: 2rem;
    }

    .content-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    
    .section-header {
        padding: 1rem;
        margin-bottom: 2rem;
    }

    .tech-card {
        padding: 1.25rem;
    }
}

@media (max-width: 1442px) {
  header {
    padding: 0.5rem;
  }

  nav {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 0.5rem;
  }

  .nav-left {
    flex-direction: row;
    gap: 0.25rem;
  }

  .logo {
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
  }

  .logo a {
    font-size: 1.3rem;
    padding: 0;
  }

  .mobile-menu-button {
    display: block;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--text-primary);
    cursor: pointer;
    padding: 0.25rem;
    margin: 0;
  }

  .nav-left ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--nav-bg);
    padding: 1rem;
    flex-direction: column;
    text-align: center;
    border-top: 1px solid var(--border-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .nav-left ul.show {
    display: flex;
  }

  .nav-right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .nav-right .search-container {
    width: auto;
  }

  .nav-right .search-container input {
    display: none;
  }

  .nav-right .search-container::before {
    content: '🔍';
    font-size: 1.2rem;
    cursor: pointer;
  }

  .nav-right .search-container:hover input,
  .nav-right .search-container input:focus {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    width: 200px;
    padding: 0.5rem;
    background-color: var(--nav-bg);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .theme-toggle,
  .auth-btn,
  .profile-button {
    padding: 0.4rem;
    font-size: 0.9rem;
    min-width: fit-content;
  }

  .auth-btn {
    white-space: nowrap;
    padding: 0.4rem 0.8rem;
  }

  .logo {
    font-size: 1.3rem;
    margin-left: 0;
    padding-left: 0;
  }

  .logo a {
    font-size: 1.3rem;
  }
}

/* Profile Menu */
.profile-menu-container {
  position: relative;
}

.profile-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--accent-color);
  transition: transform 0.2s;
}

.profile-button:hover .profile-avatar {
  transform: scale(1.05);
}

.profile-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background-color: var(--modal-bg);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  z-index: 1000;
  overflow: hidden;
}

.profile-info {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.profile-avatar-large {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--accent-color);
}

.profile-details {
  display: flex;
  flex-direction: column;
}

.profile-name {
  font-weight: 500;
  color: var(--text-primary);
}

.profile-email {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.profile-menu-items {
  padding: 0.5rem;
}

.profile-menu-items button {
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  background: none;
  border: none;
  border-radius: 4px;
  color: var(--text-primary);
  cursor: pointer;
  transition: background-color 0.2s;
}

.profile-menu-items button:hover {
  background-color: var(--bg-secondary);
}

/* Footer */
footer {
    background-color: var(--bg-secondary);
    padding: 2rem 1rem;
    margin-top: auto;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.footer-logo {
    font-size: 1.5rem;
    font-weight: bold;
}

.footer-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    text-align: center;
}

.footer-copyright {
    color: var(--text-secondary);
    font-size: 0.9rem;
}

.footer-links {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-links a {
    color: var(--text-secondary);
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: var(--accent-color);
}

@media (max-width: 768px) {
    .footer-links {
        flex-direction: column;
        gap: 1rem;
    }

    .footer-bottom {
        gap: 1rem;
    }

    footer {
        padding: 1.5rem 1rem;
    }
}

/* ReCAPTCHA */
.grecaptcha-badge {
    visibility: hidden !important;
}

.captcha-container {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}

.captcha-container > div {
    transform: scale(0.9);
    transform-origin: center;
}

@media (max-width: 350px) {
    .captcha-container > div {
        transform: scale(0.8);
    }
}

/* Profile Settings */
.profile-settings {
  max-width: 600px !important;
}

.settings-tabs {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 1rem;
}

.tab-btn {
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  color: var(--text-secondary);
  cursor: pointer;
  font-size: 1rem;
  border-radius: 6px;
  transition: all 0.2s;
}

.tab-btn:hover {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.tab-btn.active {
  background-color: var(--accent-color);
  color: white;
}

.settings-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: var(--text-primary);
  font-size: 0.9rem;
}

.form-group input,
.form-group textarea {
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: 1rem;
  transition: border-color 0.2s;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--accent-color);
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.new-discussion-button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--primary-color);
    color: white;
    border: 3px solid var(--accent-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease;
    font-size: 32px;
    font-weight: bold;
}

/* Liens dans les discussions et commentaires */
.discussion-content a,
.comment-content a {
    color: #5e8ab6;
    text-decoration: none;
    transition: color 0.3s ease;
}

.discussion-content a:hover,
.comment-content a:hover {
    text-decoration: underline;
}

/* Bouton Voir Plus */
.load-more-container {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
}

.load-more-btn {
    padding: 0.75rem 2rem;
    background-color: #46d160;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.load-more-btn:hover {
    background-color: #348142;
    color: white;
    transform: translateY(-2px);
}

/* Styles pour la section Actualités Tech */
.tech-news-section {
  padding: 2rem 10%;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 1800px) {
  .tech-news-section {
    padding: 2rem 0;
  }
}

.tech-news-section .container {
  width: 100%;
  padding: 0 1rem;
}

.tech-news-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5rem;
  margin-top: 2rem;
}

.tech-news-card {
  background: var(--card-background);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: var(--card-shadow);
  transition: all 0.3s ease;
  border: 1px solid var(--border-color);
}

.tech-news-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--card-shadow-hover);
}

.tech-news-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid var(--border-color);
}

.tech-news-content {
  padding: 1.5rem;
}

.tech-news-content h2 {
  margin: 0 0 1rem 0;
  font-size: 1.4rem;
  color: var(--text-color);
  line-height: 1.4;
  font-weight: 600;
}

.tech-news-content p {
  color: var(--text-color-secondary);
  margin-bottom: 1.5rem;
  line-height: 1.6;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tech-news-content .article-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
  font-size: 0.9rem;
  color: var(--text-color-secondary);
}

.tech-news-content .source {
  font-weight: 500;
  color: var(--primary-color);
}

.tech-news-content .date {
  font-style: italic;
}

.tech-news-content .read-more {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  font-size: 0.9rem;
}

.tech-news-content .read-more:hover {
  color: var(--primary-color-hover);
  text-decoration: underline;
}

@media (max-width: 1450px) {
  .tech-news-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  /* Première ligne : 2 cartes */
  .tech-news-card:nth-child(1),
  .tech-news-card:nth-child(2) {
    grid-column: span 1;
  }

  /* Deuxième ligne : 2 cartes */
  .tech-news-card:nth-child(3),
  .tech-news-card:nth-child(4) {
    grid-column: span 1;
  }

  /* Dernière carte centrée */
  .tech-news-card:nth-child(5) {
    grid-column: 1 / -1;
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .tech-news-container {
    grid-template-columns: 1fr;
  }
  
  .tech-news-card {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
  }
  
  .tech-news-card:nth-child(4),
  .tech-news-card:nth-child(5) {
    grid-column: auto;
  }
}

.loading-message,
.error-message {
  grid-column: 1 / -1;
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: var(--text-color);
  background: var(--card-background);
  border-radius: 15px;
  border: 1px solid var(--border-color);
  box-shadow: var(--card-shadow);
}

.error-message {
  color: var(--error-color, #dc3545);
}

.tech-news-section h2 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--text-color);
  font-size: 2.5rem;
  font-weight: 700;
}
