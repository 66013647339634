.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: var(--background-color);
    border-radius: 8px;
    padding: 20px;
    min-width: 1000px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal-header h2 {
    margin: 0;
    color: var(--text-primary);
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--text-secondary);
    padding: 5px;
    line-height: 1;
}

.close-button:hover {
    color: var(--text-primary);
}

.form-group {
    margin-bottom: 20px;
}

.title-input {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 16px;
    background: var(--input-background);
    color: var(--text-primary);
    margin-bottom: 15px;
}

.title-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.2);
}

.submit-button {
    background-color: var(--primary-color);
    color: #ffffff;
    padding: 10px 20px;
    border: solid 2px #46d160;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 50%;
    transition: background-color 0.2s;
}

.submit-button:hover {
    background-color: var(--primary-color-dark);
    color: #46d160;
}

/* Rich Text Editor specific styles */
.form-group .rich-text-editor {
    margin-bottom: 20px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

.form-group .rich-text-editor .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.form-group .rich-text-editor .ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 200px;
}

@media (max-width: 768px) {
    .modal-content {
        min-width: unset;
        width: 95%;
        padding: 15px;
    }
}
