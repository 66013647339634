@import '../styles/components/Hero.css';

/* Hero Section */
.voice-assistants-page {
    min-height: 100vh;
    padding-bottom: 2rem;
}

/* Override hero background for this page */
.hero {
    background: linear-gradient(to right, var(--background-color) 60%, transparent);
}

@media (max-width: 768px) {
    .hero {
        background: linear-gradient(to bottom, var(--background-color) 60%, transparent);
    }
}

/* Forum Section Styles */
.discussions-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.discussions-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.loading {
    text-align: center;
    padding: 2rem;
    color: var(--text-secondary);
}

.new-discussion-button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease;
    z-index: 100;
}

.new-discussion-button:hover {
    transform: scale(1.1);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: var(--background-color);
    border-radius: 10px;
    padding: 2rem;
    width: 50%;
    max-width: 1000px;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close-modal {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--text-secondary);
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s ease;
    z-index: 2;
}

.close-modal:hover {
    color: var(--text-color);
}

.modal h2 {
    width: 100%;
    text-align: center;
    margin: 0 0 2.5rem 0;
    color: var(--text-color);
    font-size: 2rem;
    font-weight: bold;
    padding: 1rem 0;
    border-bottom: 2px solid var(--border-color);
}

.modal form {
    width: 100%;
    max-width: 1600px; 
    padding: 0 2rem;
}

.form-group {
    margin-bottom: 2rem;
    width: 100%;
}

.form-group label {
    display: block;
    margin-bottom: 0.8rem;
    color: var(--text-color);
    font-weight: 500;
    font-size: 1.2rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 1.2rem;
    border: 2px solid var(--border-color);
    border-radius: 8px;
    background: var(--background-secondary);
    color: var(--text-color);
    font-size: 1.1rem;
}

.form-group input {
    height: 60px;
}

.form-group textarea {
    min-height: 300px;
    resize: vertical;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: var(--primary-color);
}

.form-actions {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 3rem;
    width: 100%;
}

.form-actions button {
    padding: 1.2rem 3rem;
    border: none;
    border-radius: 8px;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    background: var(--primary-color);
    min-width: 200px;
}

.cancel-button {
    color: #FF5252;
}

.submit-button {
    color: #4CAF50;
}

.form-actions button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

@media (max-width: 1200px) {
    .modal {
        width: 95%;
        max-width: 1200px;
    }
    
    .modal form {
        max-width: 1000px;
    }
}

@media (max-width: 768px) {
    .modal {
        padding: 1.5rem;
        height: 90vh;
        overflow-y: auto;
    }

    .modal form {
        padding: 0 1rem;
    }

    .form-group input {
        height: 50px;
    }

    .form-group textarea {
        min-height: 200px;
    }

    .form-actions {
        flex-direction: column;
        gap: 1rem;
    }

    .form-actions button {
        width: 100%;
        padding: 1rem;
    }
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column;
        padding: 2rem 1rem;
    }

    .hero-left {
        max-width: 100%;
    }

    .hero-content {
        padding: 1rem;
        text-align: center;
    }

    .hero-content h1 {
        font-size: 2rem;
    }

    .hero-image {
        position: relative;
        width: 100%;
        height: 200px;
        margin-top: 1rem;
    }

    .new-discussion-button {
        bottom: 1rem;
        right: 1rem;
        width: 50px;
        height: 50px;
    }
}
